import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import gsap from 'gsap'

// window.addEventListener('resize', checkLandscape)

function animCrogiuolo() {
  // THREEJS

  let camera, scene, renderer, controls, target, manager
  let core, fan, part1, part2, part3, tramoggia, bar1, bar2, bar3, bar4

  init()
  // render()

  function init() {
    const container = document.querySelector('#crg-container')
    // console.log(container)

    camera = new THREE.PerspectiveCamera(
      35,
      container.offsetWidth / container.offsetHeight,
      0.01,
      1000,
    ) // l'aspect ratio della camera è 1 (il div container è quadrato)
    camera.position.set(-61, 323, -132)

    scene = new THREE.Scene()
    scene.name = 'crogiuolo'
    // scene.background = new THREE.Color(0x272c32)
    // scene.background = new THREE.Color(0xeeeeee)

    // scene.fog = new THREE.Fog(0x272c32, 350, 380)

    // let manager = new THREE.LoadingManager(onLoad)
    manager = new THREE.LoadingManager(onLoad, onLoadingProgress)
    manager.loadedPercentage = 0
    function onLoadingProgress(url, loaded, total) {
      manager.loadedPercentage = (loaded / total) * 100
      // console.log(url, loaded, total)
    }

    new RGBELoader(manager)
      .setDataType(THREE.UnsignedByteType)
      .setPath('textures/equirectangular/')
      .load('royal_esplanade_1k.hdr', function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture

        // scene.background = envMap
        scene.environment = envMap

        texture.dispose()
        pmremGenerator.dispose()
      })

    const gltfLoader = new GLTFLoader(manager).setPath('models/')

    gltfLoader.load('crogiuolo.glb', gltf => {
      // console.log(gltf)
      core = gltf.scene.children[0]
      fan = gltf.scene.children[1]
      part1 = gltf.scene.children[2]
      part2 = gltf.scene.children[3]
      part3 = gltf.scene.children[4]
      tramoggia = gltf.scene.children[5]
      bar4 = gltf.scene.children[6]
      bar2 = gltf.scene.children[7]
      bar3 = gltf.scene.children[8]
      bar1 = gltf.scene.children[9]

      scene.add(gltf.scene)
      // render()
      // console.log('primo render')
    })

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    renderer.setPixelRatio(window.devicePixelRatio)

    renderer.setSize(container.offsetWidth, container.offsetHeight)
    // console.log(container.offsetHeight, container.offsetWidth)
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding
    container.appendChild(renderer.domElement)

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()

    // controls = new OrbitControls(camera, renderer.domElement)
    // controls.addEventListener('change', () => {
    //   console.log('change')
    //   render()
    // }) // use if there is no animation loop
    // controls.minDistance = 0
    // controls.maxDistance = Infinity
    // controls.target.set(21, 88, 5.24)
    // controls.enableZoom = false
    // controls.enableRotate = false
    // controls.enablePan = false
    // controls.update() // triggera il change
    // .preventDefault()

    // setInterval(() => {
    //   console.log(camera.position, controls.target)
    // }, 1000)

    // window.addEventListener('resize', onWindowResize)
    //   window.addEventListener('click', e => (window.location.href = 'earlyWorks.html'))
    // N.B. per far funzionare lo scroll-per gsap-sulla canvas, occorre disabilitare gli orbit Controls, e impostare da CSS-> touch-action: pan-y
  }

  function onWindowResize() {
    const container = document.querySelector('#crg-container')
    camera.aspect = container.offsetWidth / container.offsetHeight
    camera.updateProjectionMatrix()
    // render()
    // console.log('resize')
  }

  //

  function render() {
    renderer.render(scene, camera)
    // console.log('render')
    // requestAnimationFrame(render)
  }

  target = new THREE.Vector3(21, 88, 5.24)

  function onLoad() {
    // console.log(scene)
    let background = document.querySelector('#v-Crogiuolo')

    var crogiuoloTL = gsap
      .timeline({
        scrollTrigger: {
          trigger: '#v-Crogiuolo',
          pin: true,
          start: 'top top',
          end: '+=200%',
          // markers: true,
          // toggleActions: 'play reverse none none',
          scrub: 0.25,
          onEnter: render,
        },
        defaults: {
          ease: 'power1.inOut',
          onUpdate: function () {
            camera.lookAt(target)
            // console.log('updateCRG')
            // console.log(scene)
            render()
          },
        },
      })
      .set(background, { backgroundSize: '100% 100%' })
    if (window.media.isMobile) {
      crogiuoloTL
        .to('#crg-text', {
          opacity: 0,
          duration: 0.25,
          scale: 0.9,
        })
        .from(
          '#crg-container',
          {
            opacity: 0.3,
            duration: 0.25,
          },
          '<',
        )
    }
    crogiuoloTL
      // .pause()
      .to(camera.position, { x: -250, y: -105, z: 131, duration: 1 }, '0')
      .to(target, { x: -4.3, y: 71.4, z: -2.1, duration: 1 }, '0')
      .from(bar1.position, { y: '+=250', duration: 0.4 }, '0')
      .from(bar2.position, { y: '+=300', duration: 0.45 }, '0')
      .from(bar3.position, { y: '+=650', duration: 0.55 }, '0')
      .from(bar4.position, { y: '+=500', duration: 0.5 }, '0')
      .from(core.position, { y: '+=500', duration: 0.5 }, '0')
      .from(tramoggia.position, { z: '-=60', y: '+=50', duration: 0.7 }, '0.3')
      .from(part1.position, { z: '-=60', y: '-=30', duration: 0.7 }, '0.3')
      .from(part2.position, { z: '+=60', y: '+=30', duration: 0.7 }, '0.3')
      .from(part3.position, { y: '-=80', duration: 0.8 }, '0.3')
      .from(fan.position, { z: '+=100', y: '+=120', duration: 0.8 }, '0.3')
      .to(
        background,
        { backgroundSize: '130% 130%', duration: 0.5, ease: 'power1.in' },
        '0',
      )
      .to(
        background,
        { backgroundSize: '110% 110%', duration: 0.6, ease: 'power1.out' },
        '>',
      )
  }
  return {
    loadingManager: manager,
  }
}

export { animCrogiuolo }
