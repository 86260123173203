import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader'
//import gsap from 'gsap'

// window.addEventListener('resize', checkLandscape)
window.orientationMode =
  window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'

function FBLogo() {
  // THREEJS

  let camera, scene, renderer

  init()
  // render()

  function init() {
    const container = document.querySelector('#FBlogo-container')

    camera = new THREE.PerspectiveCamera(45, 1, 0.25, 20) // l'aspect ratio della camera è 1 (il div container è quadrato)
    camera.position.set(-0.207, -0.501, 5.836)

    scene = new THREE.Scene()
    scene.name = 'FBLogo'

    //   scene.background = new THREE.Color(0xffffff)
    console.log(scene)

    let loadingManager = new THREE.LoadingManager(render)
    //   manager.loadedPercentage = 0
    //   function onLoadingProgress(url, loaded, total) {
    //     // let xhr = $.ajax({
    //     //   type: 'HEAD',
    //     //   url: url,
    //     //   success: function(msg) {
    //     //     // console.log(url + ' ' + xhr.getResponseHeader('Content-Length') / 1000 + ' KB')
    //     //   },
    //     // })
    //     manager.loadedPercentage = (loaded / total) * 100
    //   }

    new RGBELoader(loadingManager)
      .setDataType(THREE.UnsignedByteType)
      .setPath('textures/equirectangular/')
      .load('royal_esplanade_1k.hdr', function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture

        //   scene.background = envMap
        scene.environment = envMap

        texture.dispose()
        pmremGenerator.dispose()
      })

    const gltfLoader = new GLTFLoader().setPath('models/')

    gltfLoader.load('B3DSharp.glb', gltf => {
      let gltfModel = gltf.scene.children[0]
      gltfModel.name = 'B'
      gltfModel.scale.set(1, 1, 1)
      gltfModel.rotation.x = (90 / 180) * Math.PI
      // const geoEdg = new THREE.EdgesGeometry(gltf.scene.children[0].geometry) // or WireframeGeometry
      // const matEdg = new THREE.LineBasicMaterial({ color: 0x272c32, linewidth: 3 })
      // const wireframe = new THREE.LineSegments(geoEdg, matEdg)

      // gltf.scene.children[0].add(wireframe)

      scene.add(gltfModel)
    })

    gltfLoader.load('F3DSharp.glb', gltf => {
      let gltfModel = gltf.scene.children[0]
      gltfModel.name = 'F'
      gltfModel.scale.set(1, 1, 1)
      gltfModel.rotation.x = (90 / 180) * Math.PI
      // const geoEdg = new THREE.EdgesGeometry(gltf.scene.children[0].geometry) // or WireframeGeometry
      // const matEdg = new THREE.LineBasicMaterial({ color: 0x272c32, linewidth: 3 })
      // const wireframe = new THREE.LineSegments(geoEdg, matEdg)

      // gltf.scene.children[0].add(wireframe)

      scene.add(gltfModel)
    })
    // THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1)
    // const rhinoLoader = new Rhino3dmLoader(loadingManager)
    // rhinoLoader.setLibraryPath(
    //   'https://cdn.jsdelivr.net/npm/rhino3dm@0.15.0-beta/',
    // )
    // rhinoLoader.load('models/Bmat.3dm', object => {
    //   const material = new THREE.MeshStandardMaterial({
    //     color: new THREE.Color(0, 0.193, 0.298),
    //     metalness: 1,
    //     roughness: 0.36,
    //     side: 2,
    //   }) // non sembra funzionare
    //   console.log(object)
    //   scene.add(object)

    //   const light = new THREE.PointLight(0xffffff, 1, 0, 1)
    //   light.position.set(0, 0, 0)
    //   scene.add(light)
    // })

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })
    renderer.setPixelRatio(window.devicePixelRatio)
    if (window.orientationMode == 'portrait') {
      renderer.setSize(container.offsetWidth, container.offsetHeight)
    } else {
      renderer.setSize(
        container.offsetWidth * 1.3,
        container.offsetHeight * 1.3,
      )
    }
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding
    //   renderer.domElement.style.position = 'absolute'
    //   renderer.domElement.style.top = '0px'
    container.appendChild(renderer.domElement)

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()

    const controls = new OrbitControls(camera, renderer.domElement)
    controls.addEventListener('change', render) // use if there is no animation loop
    controls.minDistance = 2
    controls.maxDistance = 10
    controls.target.set(-0.042, -0.243, -0.238)
    controls.enableZoom = false
    controls.enableRotate = true
    controls.enablePan = false
    // controls.enableDamping = true // per attivare l'inerzia. Però bisogna mettere update nell'animation loop
    controls.update()

    //   setInterval(() => {
    //     console.log(camera.position, controls.target)
    //   }, 1000)

    window.addEventListener('resize', onWindowResize)
    //   window.addEventListener('click', e => (window.location.href = 'earlyWorks.html'))
  }

  function onWindowResize() {
    const container = document.querySelector('#FBlogo-container')
    //   camera.aspect = ((container.offsetWidth * 1.3) / container.offsetHeight) * 1.3
    //   camera.updateProjectionMatrix()
    window.orientationMode =
      window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    if (window.orientationMode == 'portrait') {
      renderer.setSize(container.offsetWidth, container.offsetHeight)
    } else {
      renderer.setSize(
        container.offsetWidth * 1.3,
        container.offsetHeight * 1.3,
      )
    }
    render()
  }

  //

  function render() {
    renderer.render(scene, camera)
  }
}

export { FBLogo }
