import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)
import { FBLogo } from './FBLogo.js'
import { parametricLandscape } from './parametricLandscape.js'
import { animCrogiuolo } from './animCrogiuolo.js'
import { BooleanKeyframeTrack } from 'three'
import { animTolomeo } from './animTolomeo.js'
import { animBlink } from './animBlink.js'
import { animFresata } from './animFresata.js'
import { animateLoadingScreen } from './animateLoadingScreen.js'

// window.addEventListener('resize', checkLandscape)
window.media = {
  type: '',
  isMobile: false,
  isDesktop: false,
  isTablet: false,
  isPortrait: false,
  isLandscape: false,
}

if (window.matchMedia('(orientation: portrait)').matches) {
  window.media.type = 'mobile portrait'
  window.media.isMobile = true // non metto la suddivisione in base ai pixel altrimenti rischierebbero di restare alcuni gap scoperti
  window.media.isPortrait = true
} else if (
  window.matchMedia('(orientation: landscape)').matches &&
  window.matchMedia('(max-device-width: 812px)').matches
) {
  window.media.type = 'mobile landscape'
  window.media.isMobile = true
  window.media.isLandscape = true
} else {
  window.media.type = 'desktop'
  window.media.isDesktop = true
  window.media.isLandscape = true
}
console.log(window.media)

// const superManager = new THREE.LoadingManager()

// window.onload = parametricLandscape

// setTimeout(function () {
//   window.scrollTo(0, 0)
//   console.log('document ready')
// }, 1000)

// function checkLandscape() {
//   if (window.innerHeight > window.innerWidth && document.querySelector('#pleaseTurn') == null) {
//     let turningScreen = document.createElement('div')
//     turningScreen.id = 'pleaseTurn'
//     document.body.appendChild(turningScreen)
//     // alert('Please use Landscape!')
//   } else if (document.querySelector('#pleaseTurn') != null) {
//     document.querySelector('#pleaseTurn').remove()
//   }
// }
// gsap.to('#tramoggia', {
//   scrollTrigger: {
//     trigger: '#tramoggia',
//     markers: true,
//     start: 'top bottom',
//     end: 'bottom top',
//     // toggleActions: 'play reverse none none',
//     scrub: 0.5,
//   },
//   // toggleActions: 'restart pause none none',
//   // right: '50%',
//   left: '100vw',
//   //   duration: 3,
// })

// FBLogo()
var parametricLandscapeData = parametricLandscape()
// console.log(parametricLandscapeData)

gsap.fromTo(
  '#softwares3D .flex-item-container',
  { opacity: 0, scale: 1.3 },
  {
    scrollTrigger: {
      trigger: '#softwares3D',
      toggleActions: 'play none none reverse ',
      start: 'top 60%',
    },
    //   display: inline - block,
    opacity: 1,
    scale: 1,
    // ease: 'power3.In',
    duration: 0.6,
  },
)

// var grasshopperAnimCopy = gsap
//   .timeline({
//     scrollTrigger: {
//       trigger: '#v-GrasshopperCopy',
//       pin: '#v-GrasshopperCopy',
//       scrub: 0.25,
//       // toggleActions: 'play none none reverse',
//       start: 'top top',
//       end: 'center 30%',
//       markers: true,

//       // pinSpacing: false,
//     },
//   })
//   .fromTo(
//     '#conchiglia1Copy',
//     {
//       y: '+=' + movingFactor + 'vh',
//     },
//     {
//       y: '-=' + movingFactor + 'vh',
//       // duration: 10,
//     },
//   )

// gsap.fromTo(
//   '.conchiglia-img',
//   { opacity: 0, scale: 1.3 },
//   {
//     scrollTrigger: {
//       trigger: '#v-grasshopper',
//       toggleActions: 'play none none reverse',
//       start: 'top center',
//     },
//     //   display: inline - block,
//     y: '+=' + 0.5 * window.innerHeight,
//     opacity: 1,
//     scale: 1,
//     // ease: 'power3.In',
//     duration: 0.6,
//   },
// )

let movingFactor = 3

if (window.media.isMobile) {
  var grasshopperAnim = gsap
    .timeline({
      scrollTrigger: {
        trigger: '#v-Grasshopper',
        // pin: '#v-Grasshopper',
        scrub: 0.25,
        // toggleActions: 'play none none reverse',
        start: 'top bottom',
        end: 'bottom top',
        // markers: true,
        // pinSpacing: 'margin',

        // pinSpacing: false,
      },
      defaults: {
        ease: 'power1.inOut',
      },
    })
    .to(
      '#grassScreenshot-img',
      // { scale: 1, x: '-95%', y: '-30%' },
      { scale: 1.25, x: '-=125%', y: '+40%' },
      '0',
    )
    .from(
      '.conchtop-img',
      {
        // opacity: 0.25,
        y: '-=70%',
        rotation: 20,
        scale: 1.35,
        // ease: 'power1.inOut',
      },
      '0',
    )
} else {
  var grasshopperAnim = gsap
    .timeline({
      scrollTrigger: {
        trigger: '#v-Grasshopper',
        // pin: '#v-Grasshopper',
        scrub: 0.25,
        // toggleActions: 'play none none reverse',
        start: 'top bottom',
        end: 'bottom top',
        // markers: true,
        // pinSpacing: 'margin',

        // pinSpacing: false,
      },
      defaults: {
        ease: 'power1.in',
      },
    })
    // .to('#grassScreenshot-img', { y: '+=15vh' }, '0')
    .fromTo(
      '#conch1',
      {
        y: `+=${movingFactor * 1}vh`,
        x: '+=0vw',
      },
      {
        y: `-=${movingFactor * 0 + 2}vh`,
        x: '+=1vw',

        // duration: 10,
      },
      '0',
    )
    .fromTo(
      '#conch2',
      {
        y: `+=${movingFactor * 2}vh`,
        x: '+=6vw',
      },
      {
        y: `-=${movingFactor * 6 + 2}vh`,
        x: '-=6vw',

        // duration: 10,
      },
      '0',
    )
    .fromTo(
      '#conch3',
      {
        y: `+=${movingFactor * 3}vh`,
        x: '+=12vw',
      },
      {
        y: `-=${movingFactor * 18 + 2}vh`,
        x: '-=14vw',

        // duration: 10,
      },
      '0',
    )
}
animFresata()

// gsap.to('#blink-slide1', {
//   scrollTrigger: { trigger: '#v-blink', scrub: true, start: 'top center', end: '+=100%', markers: false },
//   rotateY: '+=10deg',
// })

// gsap.set('#v-Shoes', { height: '200vh' })
gsap.from('#abbraccioOverlay', {
  scrollTrigger: {
    trigger: '#v-Abbraccio',
    // toggleActions: 'play none none reverse ',
    scrub: true,
    start: 'top bottom',
    end: 'top 10%',
  },
  opacity: 0.95, //
  ease: 'power3.in',
  // duration: 0.6,
})

// gsap.from('#abbraccio-text', {
//   opacity: 0,
//   x: '+=5vh',
//   // delay: 0.5,
//   // duration: 1,
//   ease: 'power3.out',
//   scrollTrigger: {
//     trigger: '#v-Abbraccio',
//     toggleActions: 'play none none reverse ',
//     // scrub: true,
//     start: '50% bottom',
//   },
//   duration: 0.6,
// })

// gsap.from('#shoesOverlay', {
//   y: '-=20vh',
//   scrollTrigger: {
//     trigger: '#v-Shoes',
//     scrub: true,
//     start: 'top bottom',
//     end: '+=100%',
//     // markers: true,
//   },
// })

var animShoes = gsap
  .timeline({
    scrollTrigger: {
      trigger: '#v-Shoes',
      pin: true,
      scrub: 0.25,
      start: 'top top',
      end: '+=150%',
      // markers: true,
      anticipatePin: 1,
    },
  })
  // .from('#shoes-container', {
  //   x: '+=10vh',
  //   opacity: 0,
  //   duration: 0.25,
  //   ease:'power2.inOut',
  // })
  .to('#shoes-container', { duration: 0.5 })
  .from(
    '#shoes-text',
    {
      opacity: 0,
      x: '-=5vh',
      // delay: 0.5,
      // duration: 1,
      ease: 'power3.out',
    },
    '0',
  )
  .fromTo(
    '#shoes-render',
    {
      // y: '+=10vh',
      // opacity: 0,
      '-webkit-mask-image':
        '-webkit-linear-gradient(left, rgba(0,0,0,1) -80%,  rgba(0,0,0,0) 0%',
    },
    {
      // y: '+=10%',
      // y: 0,
      // ease: 'power2.inOut',
      '-webkit-mask-image':
        '-webkit-linear-gradient(left, rgba(0,0,0,1) 100%,  rgba(0,0,0,0) 150%',
      // '-webkit-mask-image': '-webkit-linear-gradient(top, rgba(0,0,0,1) 100%,  rgba(0,0,0,1) 100%, rgba(0,0,0,0) 150%, rgba(0,0,0,0) 0%)',
      duration: 3,
      ease: 'powe3.in',
    },
  )
  .to('#shoesOverlay', { opacity: 0, duration: 3 }, '<')
  .to(
    '#shoes-text p',
    {
      color: '#eeeeeeee',
      duration: 3,
    },
    '<',
  )
  .from(
    '#shoes-text img',
    {
      // y: '+=300px',
      opacity: 0,
      duration: 3,
      ease: 'power1.in',
    },
    '<',
  )

var animSfera = gsap
  .timeline({
    scrollTrigger: {
      trigger: '#v-Sfera',
      scrub: 0.25,
      start: 'top bottom',
      end: '30% top',
      // markers: true,
    },
  })
  .from(
    '#sfera-img',
    {
      // y: '-=3vh',
      // x: '-=5vh',
      scale: 1.2,
      filter: window.media.isMobile
        ? 'drop-shadow(20vw 20vw 8vw rgba(0, 0, 0, 0.8))'
        : 'drop-shadow(10vh 0vh 8vh rgba(0, 0, 0, 0.8))',
      duration: 1,
    },
    '0',
  )

// LESMO

var animLesmo = gsap
  .timeline({
    scrollTrigger: {
      trigger: '#v-Lesmo',
      pin: '#v-Lesmo',
      scrub: 0.25,
      start: 'top top',
      end: 'bottom top',
      // markers: true,
    },
  })
  .set('#lesmo-facciata-img', {
    filter: 'brightness(40%)',
    transformOrigin: '50% 100%', // posiziono l'origine per la scala al centro in basso
  })
  .set('#lesmo-title', { color: '#272c32' })
  .from('#lesmo-facciata-img', {
    y: '+=5vh',
  })
  .to('#lesmoOverlay', { opacity: 0, duration: 1, ease: 'power3.in' }, '0')
  .to(
    '#lesmo-facciata-img',
    {
      filter: 'brightness(100%) drop-shadow(1vh 0vh 1cm rgba(0, 0, 0, 0.2))',
      duration: 1,
      scale: 0.98,
      ease: 'power3.in',
    },
    '0',
  )
  .from(
    '#lesmo-title',
    {
      y: '80vh',
      opacity: 0.8,
      // scale: 0.8,
      duration: 1,
      ease: 'power2.inOut',
      // color: 'rgb(150, 254, 255)',
    },
    '0',
  )
  .from(
    '#lesmo-btm-border',
    {
      x: '-=100%',
      y: '+=100%',
      opacity: 0,
      duration: 1,
      ease: 'power3.inOut',
    },
    '0',
  )
  .to(
    '#lesmo-title',
    {
      // y: '+=' + 50 + 'vh',
      // opacity: 0.8,
      // scale: 0.8,
      duration: 0.8,
      color: '#eeeeee',
    },
    '0.2',
  )
  .from(
    '#lesmo-text',
    {
      opacity: 0,
      x: '+=5vh',
      // delay: 0.5,
      // duration: 1,
      ease: 'power3.out',
      duration: 0.25,
    },
    '0.80',
  )
  .to({}, { duration: 0.5 }) // pausa

// var animLesmoGallery = gsap
//   .timeline({
//     scrollTrigger: {
//       trigger: '#v-LesmoGallery',
//       // pin: '#v-LesmoGallery',
//       scrub: 0.25,
//       start: 'top top',
//       end: 'bottom bottom',
//       // markers: true,
//     },
//   })
//   .to('#lesmo-gallery-container', {
//     bottom: '2cm',
//   })

const lesmoAnimImgs = gsap.utils.toArray('.lesmo-gallery-img')
lesmoAnimImgs.forEach(elem => {
  gsap.from(elem, {
    scrollTrigger: {
      trigger: elem,
      // pin: '#v-LesmoGallery',
      // scrub: 0.25,
      start: 'top 60%',
      toggleActions: 'play none none reverse',
      // end: 'bottom bottom',
      // markers: true,
    },
    opacity: 0,
    y: '+=10vh',
    scale: 0.95,
  })
})

const animFadeInFromRight = gsap.utils.toArray('.fadeIn-fromRight')
animFadeInFromRight.forEach(elem => {
  gsap.from(elem, {
    scrollTrigger: {
      trigger: elem,
      start: '50% bottom',
      toggleActions: 'play none none reverse ',
      // scrub: true,
    },
    opacity: 0,
    x: '+=5vh',
    // delay: 0.5,
    // duration: 1,
    ease: 'power2.out',
    duration: 0.4,
  })
})

const animFadeInFromLeft = gsap.utils.toArray('.fadeIn-fromLeft')
animFadeInFromLeft.forEach(elem => {
  gsap.from(elem, {
    scrollTrigger: {
      trigger: elem,
      start: 'bottom bottom',
      toggleActions: 'play none none reverse ',
      // scrub: true,
    },
    opacity: 0,
    x: '-=5vh',
    // delay: 0.5,
    // duration: 1,
    ease: 'power2.out',
    duration: 0.4,
  })
})

gsap.fromTo(
  '#codes .flex-item-container',
  { opacity: 0, scale: 1.3 },
  {
    scrollTrigger: {
      trigger: '#codes',
      toggleActions: 'play none none reverse ',
      start: 'top 60%',
    },
    //   display: inline - block,
    opacity: 1,
    scale: 1,
    // ease: 'power3.In',
    duration: 0.6,
  },
)

var tolomeoData = animTolomeo()
// console.log(tolomeoData)

gsap.from('#blinkLogo-img', {
  scrollTrigger: {
    trigger: '#blinkLogo-img',
    start: '-20% top',
    // end: '+=100%',
    // markers: true,
    toggleActions: 'play none none reverse ',
    // pinType: 'transform',
  },
  scale: 1.3,
  opacity: 0,
  // y: '+=5vh',
  duration: 0.25,
})

gsap
  .timeline({
    scrollTrigger: {
      trigger: '#v-blink',
      scrub: true,
      pin: true,
      start: '12% top',
      end: '+=150%',
      // markers: true,
      // pinType: 'transform',
    },
  })
  .to({}, { duration: 0.5 })
  .fromTo(
    '#blinkCentral-img',
    {
      y: '+=10vh',
      scale: 1.1,
      // opacity: 0,
      filter: 'drop-shadow(0vw 0vw 0vw rgba(0, 0, 0, 0))',
      '-webkit-mask-image':
        '-webkit-linear-gradient(top, rgba(0,0,0,1) -80%,  rgba(0,0,0,0) 0%',
    },
    {
      y: 0,
      scale: 1,
      ease: 'power1.inOut',
      filter: 'drop-shadow(0vw 0vw 5vw rgba(0, 0, 0, 0.5))',
      '-webkit-mask-image':
        '-webkit-linear-gradient(top, rgba(0,0,0,1) 100%,  rgba(0,0,0,0) 150%',
      // '-webkit-mask-image': '-webkit-linear-gradient(top, rgba(0,0,0,1) 100%,  rgba(0,0,0,1) 100%, rgba(0,0,0,0) 150%, rgba(0,0,0,0) 0%)',
      duration: 1,
    },
  )
  .from(
    '#blinkSite-btn, #v-blink p',
    {
      // y: '+=10vh',
      opacity: 0,
      duration: 1,
    },
    '-=0.5',
  )

var crogiuoloData = animCrogiuolo()
// console.log(crogiuoloData)
// a loop for the other sections '.section'
// var sections = gsap.utils.toArray('.sec').forEach(function (elem) {
//   // select the relevant elements
//   var lines = elem.querySelectorAll('.lines')

//   var tl = gsap
//     .timeline({
//       scrollTrigger: {
//         trigger: elem,
//         pin: elem,
//         scrub: 0.5,
//         start: 'top top',
//         end: '+=200%',
//         markers: true,
//       },
//     })
//     .from(lines, { xPercent: -100, duration: 1, ease: 'power2.out', stagger: 0.6 }, 0)
//     .to({}, { duration: 1 }) // a little pause at the end
// })

// gsap.to('#Tramoggia', {
//   right: '50%',
//   rotation: 2,
//   duration: 3,
// })

// let st = ScrollTrigger.create({
//   trigger: '.layer1',
//   start: 'top center',
//   end: '+=500',
// })

// TolomeoScene() // three.rgbeloader bad file format bad initial token.....
// animCrogiuolo()

// animBlink()
//FBLogo.renderer.render(FBLogo.scene, FBLogo.camera)

window.onload = () => {
  animateLoadingScreen(
    [
      tolomeoData.loadingManager,
      crogiuoloData.loadingManager,
      parametricLandscapeData.loadingManager,
    ],
    parametricLandscapeData.animIntro,
  )
}
