import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'

function animTolomeo() {
  var controls
  // var water = new THREE.Object3D()
  var light,
    camera,
    scene,
    renderer,
    raycaster,
    envMap,
    domEvents,
    pmremGenerator,
    clock,
    manager,
    bulbLight,
    bulb
  var raycaster = new THREE.Raycaster()

  var mouse = new THREE.Vector2(null, null),
    clock = new THREE.Clock()

  //   setInterval(function() {
  //     var distance = camera.position.distanceTo(controls.target)
  //     console.log(camera.position, controls.target)
  //   }, 1000)

  // INIZIALIZZAZIONE
  function setUp() {
    const container = document.querySelector('#Tolomeo-container')

    // container = document.createElement( 'div' );
    // document.body.appendChild( container );

    camera = new THREE.PerspectiveCamera(
      30,
      container.offsetWidth / container.offsetHeight,
      0.1,
      10000,
    )

    scene = new THREE.Scene()
    // scene.name = 'SicilyScene'
    // window.__scene = scene

    manager = new THREE.LoadingManager(render, onLoadingProgress)
    manager.loadedPercentage = 0
    function onLoadingProgress(url, loaded, total) {
      manager.loadedPercentage = (loaded / total) * 100
      // console.log(url, loaded, total)
    }

    // manager.loadedPercentage = 0
    // function onLoadingProgress(url, loaded, total) {
    //   manager.loadedPercentage = (loaded / total) * 100
    // }

    new RGBELoader(manager)
      .setDataType(THREE.UnsignedByteType)
      .setPath('/textures/equirectangular/')
      .load('royal_esplanade_1k.hdr', function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture

        // scene.background = envMap
        scene.environment = envMap

        texture.dispose()
        pmremGenerator.dispose()
      })

    // var helper = new THREE.PolarGridHelper(100, 36, 120, 60) // radius | radials | circles | divisions
    var helper = new THREE.PolarGridHelper(30, 10) // radius | radials | circles | divisions
    scene.add(helper)

    // console.log(scene)

    // light = new THREE.DirectionalLight(0xf9d559, 0.2)
    // scene.add(light)

    // var cubeTextureLoader = new THREE.CubeTextureLoader()
    // cubeTextureLoader.setPath('textures/cubeMaps/')

    // var cubeTexture = cubeTextureLoader.load(['px.jpg', 'nx.jpg', 'py.jpg', 'ny.jpg', 'pz.jpg', 'nz.jpg'])

    // scene.background = cubeTexture
    // scene.envMap = cubeTexture

    // console.log(water1)
    // let waterGeometry = new THREE.PlaneBufferGeometry(200, 200)
    bulbLight = new THREE.SpotLight(
      0xfcf53a,
      5,
      15,
      ((45 / 90) * Math.PI) / 2,
      0.3,
    )
    bulbLight.position.set(3.6, 7.75, 0)
    // let lightTarget = new THREE.Object3D()
    scene.add(bulbLight.target)
    bulbLight.target.position.set(5.5, 0, 0)
    bulbLight.castShadow = true
    bulbLight.visible = false
    scene.add(bulbLight)

    // let bulbLightH = new THREE.SpotLightHelper(bulbLight)
    // scene.add(bulbLightH)

    var loader = new GLTFLoader(manager).setPath('models/')
    loader.load('Tolomeo.glb', function (gltf) {
      gltf.scene.name = 'Tolomeo'
      // gltf.scene.traverse(function (child) {
      //   if (child.isMesh) {
      //     child.material.envMap = envMap
      //     child.matrixAutoUpdate = false //ULTIMA AGGIUNTA
      //   }
      // }) //commentato 2021
      bulb = gltf.scene.getObjectByName('Vetro_Lampadina002')
      bulb.material_off = bulb.material.clone()
      bulb.material_on = new THREE.MeshBasicMaterial({ color: 0xfffedc })

      // bulb.material.emissive = 0xfffcad
      // bulb.material.emissiveIntensity = 0

      scene.add(gltf.scene)
    })

    // let plane = new THREE.Mesh(
    //   new THREE.CircleBufferGeometry(300, 12),
    //   new THREE.MeshPhysicalMaterial({ color: 0x000000 }),
    // )
    // plane.rotation.x = -(90 / 180) * Math.PI
    // plane.receiveShadow = true
    // scene.add(plane)

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
    // renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(container.offsetWidth, container.offsetHeight)
    // renderer.setSize( window.innerWidth, window.innerHeight );
    renderer.toneMapping = THREE.ACESFilmicToneMapping // Added from FBLogo
    renderer.toneMappingExposure = 1 // Added from FBLogo

    renderer.outputEncoding = THREE.sRGBEncoding
    // renderer.toneMappingExposure = 0.8 // per correggere l'esposizione di tutte le texture
    renderer.domElement.addEventListener('mousemove', mouseUpdate, false)
    container.appendChild(renderer.domElement)

    renderer.domElement.addEventListener('click', onClick, false)
    renderer.domElement.addEventListener('touchstart', onTouchStart)
    renderer.domElement.addEventListener('touchend', onTouchEnd)
    // renderer.domElement.addEventListener('dblclick', ondblclick)

    // const startButton = document.querySelector('#startButton-Tolomeo')
    // startButton.addEventListener('click', startTolomeo)

    function startTolomeo(e) {
      controls.addEventListener('change', render) // use if there is no animation loop
      console.log('changeTolomeo')
      // render()
    }

    function onClick() {
      // console.log('click')

      raycaster.setFromCamera(mouse, camera)
      var intersect = raycaster.intersectObjects(
        scene.getObjectByName('Tolomeo').children,
        false,
      )
      if (intersect.length > 0) {
        console.log(intersect[0].object.name)
        if (
          intersect[0].object.name === 'Interruttore002' ||
          intersect[0].object.name === 'Corpo_Cilindrico002'
        ) {
          bulbLight.visible = !bulbLight.visible
          bulb.material = bulbLight.visible
            ? bulb.material_on
            : bulb.material_off
          // bulb.material.transparent = !bulb.material.transparent
        } else {
          console.log(intersect[0].object.name)
        }
      }
      // turnOnOffControls()
      // console.log('switch')
    }

    var startTime, clickDuration
    function onTouchStart() {
      startTime = new Date()
      // console.log(startTime)
    }

    function onTouchEnd() {
      clickDuration = new Date() - startTime
      // console.log(clickDuration)
      onClick()
      if (clickDuration < 100) turnOnOffControls()
    }

    function turnOnOffControls() {
      controls.enableRotate = !controls.enableRotate
      controls.enablePan = !controls.enablePan
    }
    // function comeback() {
    //   App.toUpdate = true
    //   App.switchTo = 'main'
    //   App.switchFrom = 'tolomeo'
    // }

    pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()

    controls = new OrbitControls(camera, renderer.domElement)

    controls.addEventListener('change', render) // use if there is no animation loop

    if (window.media.isMobile) {
      camera.position.set(8.4, 4.9, 16)
      controls.target.set(3.36, 4.2, 3.5)
    } else {
      camera.position.set(6.24, 4.86, 17.98)
      controls.target.set(6.73, 3.94, 2.29)
    }
    // controls.maxPolarAngle = Math.PI/2*0.98;
    controls.minDistance = 0
    controls.maxDistance = Infinity
    controls.enableZoom = window.media.isMobile // sul Mobile attivo anche lo zoom
    controls.enableRotate = true
    controls.enablePan = true

    controls.update()
  }

  function mouseUpdate(event) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    event.preventDefault()
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
  }

  function render() {
    renderer.render(scene, camera)
    // console.log('onLoad')
  }

  // setInterval(() => {
  //   console.log(camera.position, controls.target)
  // }, 1000)

  setUp()
  // render()

  return {
    //   scene: scene,
    //   camera: camera,
    //   renderer: renderer,
    //   render: render,
    loadingManager: manager,
  }
}

export { animTolomeo }
