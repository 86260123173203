import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

function animBlink() {
  var c = document.getElementById('blink-slideshow-container'),
    boxes = []

  makeBoxes(5)

  function makeBoxes(n) {
    for (var i = 0; i < n; i++) {
      var b = document.createElement('div')
      boxes.push(b)
      c.appendChild(b)
    }
  }

  gsap.set(c, { perspective: 500 })

  for (var i = 0; i < boxes.length; i++) {
    var b = boxes[i]
    gsap.set(b, {
      left: `${50 - 35 / 2}vw`,
      top: `${50 - 25 / 2}vh`,
      // margin: -150,
      // margin: '0',
      width: '35vw',
      height: '25vh',
      borderRadius: '0.2cm',
      backgroundImage: 'url(https://picsum.photos/300/?image=' + String(i + 50),
      clearProps: 'transform',
      //   backfaceVisibility: 'hidden',
      position: 'absolute',
    })

    b.tl = gsap
      .timeline({ paused: true, defaults: { immediateRender: true } })
      .fromTo(
        b,
        {
          scale: 1,
          rotationY: (i / boxes.length) * -360, // - 90,
          transformOrigin: String('50% 50% -500%'),
        },
        {
          rotationY: '-=360',
          ease: 'none',
        },
      )
      .timeScale(0.05)

    // b.addEventListener('mouseover', e => {
    //   gsap.to(e.currentTarget, { opacity: 0.5, scale: 0.36, duration: 0.4, ease: 'expo' })
    // })
    // b.addEventListener('mouseout', e => {
    //   gsap.to(e.currentTarget, { opacity: 1, scale: 0.3, duration: 0.2, ease: 'back.out(3)', overwrite: 'auto' })
    // })
    // b.addEventListener('click', e => {
    //   window.open(e.currentTarget.style.backgroundImage.slice(5, -2), '_blank')
    // })
  }

  ScrollTrigger.create({
    trigger: '#v_Blink',
    start: 'top center',
    end: 'bottom center',
    markers: true,
    // onRefresh: self => {
    //   boxes.forEach((b, i) => {
    //     gsap.set(b.tl, { progress: self.progress })
    //   })
    // },
    onUpdate: self => {
      console.log(self.progress)
      boxes.forEach((b, i) => {
        gsap.to(b.tl, { progress: self.progress })
      })
    },
  })
}

export { animBlink }
