import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/all'
gsap.registerPlugin(ScrollTrigger)

function animFresata() {
  var fresataTL = gsap
    .timeline({
      scrollTrigger: {
        trigger: '#v-Fresata',
        pin: '#v-Fresata',
        scrub: 0.25,
        // toggleActions: 'play none none none ',
        start: 'top top',
        // end: () => `+=${window.innerHeight} * 5`,
        // end: `${window.innerHeight}*2 top`,
        end: '+=200%',
        // markers: true,
        // pinSpacing: false,
      },
    })
    .from('#fresata-container', { y: '+=5vh', opacity: 0, duration: 0.25 }, '0')
    .to('#fresata-container', { duration: 0.5 })
    .fromTo(
      '#fresataPurple',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2.5,
        // ease: 'sine.inOut',
      },
    )
    .to('#fresataOverlay', { opacity: 1, duration: 2.5 }, '-=2')
  if (window.media.isMobile && window.media.isPortrait) {
    fresataTL
      .to(
        '#fresataPurple',
        {
          boxShadow: 'inset 0 0 80vw 1cm #000',
          duration: 2.5,
          ease: 'power2.in',
        },
        '0',
      )
      .to({}, { duration: 1 })
      .to(
        '#fresata-container',
        {
          // y: -0.155 * window.innerHeight,
          // x: -0.3 * window.innerWidth,
          scale: 5,
          ease: 'power2.in',
          duration: 1,
          opacity: 0,
          // delay: 0.5,
        },
        '>', // evitare delay, usare il position parameter, ma occorre definire la duration prima
      )
      .from(
        '#fresata-text',
        {
          opacity: 0,
          y: '+=' + 0.1 * window.innerHeight,
          // delay: -0.5,
          // duration: 1,
          ease: 'power3.out',
        },
        '-=0.3',
      )
      .to('#fresata-text', {
        opacity: 0,
        y: '-=' + 0.1 * window.innerHeight,
        delay: 0.25,
        // duration: 1,
        ease: 'power3.in',
      })
      .from('#fresataLegno', {
        opacity: 0,
        y: '+=' + 0.1 * window.innerHeight,
        // delay: 0.5,
        // duration: 1,
        ease: 'power5.out',
      })
      .to({}, { duration: 1 })
  } else {
    // Desktop
    fresataTL
      .to(
        '#fresataPurple',
        {
          boxShadow: 'inset 4cm -1cm 5cm 1cm #000',
          duration: 2.5,
          ease: 'power2.in',
        },
        '<',
      )
      .to(
        '#fresata-container',
        {
          y: -0.155 * window.innerHeight,
          x: -0.3 * window.innerWidth,
          scale: 1.1,
          ease: 'power2.inOut',
          duration: 2,
          // delay: 0.5,
        },
        '-=0.8', // evitare delay, usare il position parameter, ma occorre definire la duration prima
      )
      .from(
        '#fresata-text',
        {
          opacity: 0,
          y: '+=' + 0.1 * window.innerHeight,
          // delay: -0.5,
          // duration: 1,
          ease: 'power3.out',
        },
        '-=0.6',
      )
      .to('#fresata-text', {
        opacity: 0,
        y: '-=' + 0.1 * window.innerHeight,
        delay: 0.25,
        // duration: 1,
        ease: 'power3.in',
      })
      .from('#fresataLegno', {
        opacity: 0,
        y: '+=' + 0.1 * window.innerHeight,
        // delay: 0.5,
        // duration: 1,
        ease: 'power5.out',
      })
      .to({}, { duration: 1 })
  }
}
export { animFresata }
