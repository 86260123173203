import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import gsap from 'gsap'

// window.addEventListener('resize', checkLandscape)

function parametricLandscape() {
  // THREEJS

  let camera, scene, renderer, controls, mesh, target, manager

  init()
  // render()
  // animate()

  function init() {
    const container = document.querySelector('#parametricLandscape-container')

    camera = new THREE.PerspectiveCamera(
      35,
      container.offsetWidth / container.offsetHeight,
      0.1,
      10000,
    ) // l'aspect ratio della camera è 1 (il div container è quadrato)
    // window.mediaVersion.isMobile?

    scene = new THREE.Scene()
    scene.name = 'parametricLandscape'
    // console.log(scene)

    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    })
    renderer.setPixelRatio(window.devicePixelRatio)

    renderer.setSize(container.offsetWidth, container.offsetHeight)
    // console.log(container.offsetHeight, container.offsetWidth)
    renderer.toneMapping = THREE.ACESFilmicToneMapping
    renderer.toneMappingExposure = 1
    renderer.outputEncoding = THREE.sRGBEncoding
    // renderer.domElement.style.position = 'absolute'
    // renderer.domElement.style.top = '0px'
    container.appendChild(renderer.domElement)

    const pmremGenerator = new THREE.PMREMGenerator(renderer)
    pmremGenerator.compileEquirectangularShader()

    // controls = new OrbitControls(camera, renderer.domElement)
    // // controls.addEventListener('change', render) // use if there is no animation loop
    // controls.minDistance = 0
    // controls.maxDistance = Infinity
    // // controls.target.set(8.27, 4.1, 2.47) // tolomeo
    // controls.enableZoom = true
    // controls.enableRotate = true
    // controls.enablePan = true
    // controls.update()

    target = new THREE.Vector3()

    // scene.background = new THREE.Color(0x272c32)
    // scene.background = new THREE.Color(0xeeeeee)

    // scene.fog = new THREE.Fog(0x272c32, 350, 380)

    manager = new THREE.LoadingManager(undefined, onLoadingProgress)
    manager.loadedPercentage = 0
    function onLoadingProgress(url, loaded, total) {
      manager.loadedPercentage = (loaded / total) * 100
      // console.log(url, loaded, total)
    }

    new RGBELoader(manager)
      .setDataType(THREE.UnsignedByteType)
      .setPath('textures/equirectangular/')
      .load('royal_esplanade_1k.hdr', function (texture) {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture

        // scene.background = envMap
        scene.environment = envMap

        texture.dispose()
        pmremGenerator.dispose()
      })

    const loader = new STLLoader(manager).setPath('models/')
    let modelFileName = window.media.isMobile
      ? 'foldingPatternLight.glb'
      : 'foldingPattern.stl'

    // console.log(modelFileName)

    if (window.media.isDesktop) {
      loader.load(modelFileName, function (geometry) {
        const material = new THREE.MeshStandardMaterial({
          color: new THREE.Color(0, 0.193, 0.298),
          // color: 0x015270,
          // color: new THREE.Color(0x333333),
          metalness: 1,
          roughness: 0.4,
          side: 2,
        })
        mesh = new THREE.Mesh(geometry, material)
        // mesh.geometry.computeVertexNormals()
        const startingRotations = [-1.6, -4.3, -5.6, -7.5]
        mesh.rotation.y = startingRotations[Math.floor(Math.random() * 4)]
        // console.log(`startingRotation: ${mesh.rotation.y}`)
        // console.log(startingRotations)
        // const geoEdg = new THREE.EdgesGeometry(mesh.geometry) // or WireframeGeometry
        // const matEdg = new THREE.LineBasicMaterial({
        //   // color: 0x272c32,
        //   color: 0xf6f4ec,
        //   linewidth: 3,
        // })
        // const wireframe = new THREE.LineSegments(geoEdg, matEdg)
        // mesh.add(wireframe)
        // console.log(mesh)
        scene.add(mesh)
        camera.position.set(-4.2, 12, -69) // Desktop
        target.set(18.7, 5.1, -3.8) // Desktop
      })
    }
    if (window.media.isMobile) {
      const gltfLoader = new GLTFLoader(manager).setPath('models/')
      gltfLoader.load(modelFileName, gltf => {
        // gltfLoader.load('foldingPattern.glb', gltf => {
        mesh = gltf.scene.children[0]
        //   gltf.scene.name = 'foldingPattern'

        //   // gltfModel.scale.set(1, 1, 1)
        //   // gltfModel.rotation.x = (90 / 180) * Math.PI
        //   // const geoEdg = new THREE.EdgesGeometry(gltfMesh.geometry) // or WireframeGeometry
        //   // const matEdg = new THREE.LineBasicMaterial({
        //   //   color: 0x272c32,
        //   //   // color: 0xf6f4ec,
        //   //   linewidth: 3,
        //   // })
        //   // const wireframe = new THREE.LineSegments(geoEdg, matEdg)
        // console.log(mesh)
        //   // gltfMesh.add(wireframe)

        scene.add(mesh)
        //   scene.add(gltfMesh)
        //   // render()
        camera.position.set(-27, 10.35, -15.8) // Mobile
        target.set(1.36, 6.41, -0.17) // Mobile
      })
    }

    const lightPurple = new THREE.PointLight(0x5005ff, 1, 0, 1)
    lightPurple.position.set(8.7, 11.7, -21.8)
    scene.add(lightPurple)

    const lightGreen = new THREE.PointLight(0x5005ff, 1, 0, 1)
    lightGreen.position.set(8.7, 11.7, -21.8)
    scene.add(lightGreen)

    const lightPink = new THREE.PointLight(0xff52f1, 1, 0, 1)
    lightPink.position.set(-8.4, 8.4, 16)
    scene.add(lightPink)

    const lightYellow = new THREE.PointLight(0xffc905, 1, 0, 1)
    lightYellow.position.set(30, 11.7, 18)
    scene.add(lightYellow)

    const lightWhite = new THREE.PointLight(0xffffff, 1, 0, 1)
    lightWhite.position.set(0, 5.5, 1.3)
    scene.add(lightWhite)

    // const gridHelper = new THREE.GridHelper(400, 100, {},)
    // scene.add(gridHelper)
    // gridHelper.rotateX(-0.24)
    // gridHelper.rotateZ(0.05)
    // gridHelper.translateY(-10)

    // setInterval(() => {
    //   console.log(camera.position, controls.target, mesh.rotation.y)
    // }, 1000)

    window.addEventListener('resize', onWindowResize)
    //   window.addEventListener('click', e => (window.location.href = 'earlyWorks.html'))
  }

  function onWindowResize() {
    const container = document.querySelector('#parametricLandscape-container')
    camera.aspect = container.offsetWidth / container.offsetHeight
    camera.updateProjectionMatrix()
    window.orientationMode =
      window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    if (window.orientationMode == 'portrait') {
      renderer.setSize(container.offsetWidth, container.offsetHeight)
    } else {
      renderer.setSize(container.offsetWidth, container.offsetHeight)
    }
    render()
  }

  //

  function render() {
    renderer.render(scene, camera)
  }

  var timerAnim
  function animate() {
    // requestAnimationFrame(animate)
    // if (stoppedAnim) return
    timerAnim = window.media.isMobile
      ? setTimeout(animate, 20)
      : setTimeout(animate, 50)

    if (renderer.domElement.getBoundingClientRect().bottom < 0) {
      // console.log('.')
      return
    }
    // console.log('+')
    renderer.render(scene, camera)
    mesh.rotation.y -= window.media.isMobile ? 0.0025 : 0.006
  }

  function onLoad() {
    var tl = gsap.timeline({
      // onStart: () => {
      //   window.scrollTo(0, 0)
      // },
      onUpdate: tween => {
        camera.lookAt(target)
        renderer.render(scene, camera)
        mesh.rotation.y -= 0.0012
      },
      onComplete: tween => {
        buildTlScroll()
        // console.log('animating')
        animate()
      },
      defaults: {
        duration: 3,
        ease: 'power3.out',
      },
    })
    tl.set('.intestazione-container', {
      transformOrigin: '50% 50%',
      opacity: 1,
      // transform: 'translateX(-200px)',
    })
    tl.from(
      camera.position,
      {
        // x: 8.7, // Tolomeo
        // y: 5.45,
        // z: 17.35,
        x: -3000, // parametricLandscape
        y: 950,
        z: -9000,
        ease: 'power2.inOut',
        duration: 3.5,
      },
      '0',
    )
    tl.from(
      '.intestazione-container',
      {
        scale: 0,
        x: window.media.isMobile ? '0vw' : '10vw',
        y: window.media.isMobile ? '25vh' : '30vh',
        ease: 'power3.in',
        duration: 2.7,
      },
      '0.60',
    )
    // tl.set('#underBackground-v1', { opacity: 1 })
  }

  function buildTlScroll() {
    var tlScroll = gsap.timeline({
      onUpdate: tween => {
        camera.lookAt(target)
        render()
      },
      scrollTrigger: {
        onLeave: tween => {
          clearTimeout(timerAnim)
        },
        onEnterBack: tween => {
          animate()
        },
        trigger: '#v1',
        start: 'top top',
        end: window.media.isMobile ? '80% top' : '70% top',
        // markers: true,
        // toggleActions: 'play reverse none none',
        scrub: 0, // con 0.5 fa scattini, ma potrebbe essere uguale con 0
      },
    })
    // tlScroll.to(camera.position, { y: '-=10' })
    if (window.media.isMobile) {
      tlScroll.to(camera.position, {
        x: -22.6,
        y: -1.8,
        z: -19,
      })
      tlScroll.to(target, { x: 1, y: 4.9, z: 0.36 }, '<')
      tlScroll.set('#parametricLandscape-container', { zIndex: 4 }, '0.05')
    } else {
      tlScroll.to(camera.position, {
        x: -18,
        y: -10,
        z: -50,
      })
      tlScroll.to(target, { x: 14, y: 6, z: -2 }, '<')
      tlScroll.set('#parametricLandscape-container', { zIndex: 4 }, '0.02')
    }

    // return tlScroll
  }
  return {
    loadingManager: manager,
    animIntro: onLoad,
  }
}

export { parametricLandscape }
