import gsap from 'gsap'

function animateLoadingScreen(managers, callback) {
  // document.querySelector('.loadingScreen').style.display = 'none'
  // document.querySelector('.loadingScreen').style.opacity = '1'
  const progressBar = document.querySelector('#progressBar')
  progressBar.style.width = '4%' // occorre settarla qui all'inizio perché mi sembra di capire che quando esegue la funzione il css non è ancora caricato e di conseguenza trova la width undefined. Ho aggiunto di eseguire animateLoadingScreen all'onReady ma non cambia nulla, quindi non capisco quale sia il problema (non l'ho cercato molto)
  // const loadingText = document.querySelector('.loadingText').querySelector('p')
  // console.log(loadingScreen)
  let lLastTarget, lTarget, perc

  //  ----- costanti dell'equazione del moto smorzato
  // console.log(l)
  lLastTarget = 4
  function loop() {
    perc = 0
    managers.forEach(manager => {
      perc = perc + manager.loadedPercentage
    })
    lTarget = perc / managers.length
    // console.log(`${x}     ${xTarget}`)
    // lTarget = 100
    // lTarget = manager.loadedPercentage || 0

    if (lTarget !== lLastTarget && lTarget > lLastTarget) {
      // console.log(lTarget)
      // console.log(l)
      gsap.to(progressBar.style, {
        width: `${lTarget}%`,
        ease: 'power2.inOut',
        duration: 3,
      })

      lLastTarget = lTarget
    }
    if (lTarget === 100 && progressBar.style.width === '100%') {
      console.log('complete')
      if (window.media.isMobile) startDesktop()
      if (window.media.isDesktop) startDesktop()
      window.scrollTo(0, 0) // verificare se serve davvero
      callback()
      console.log(callback)
      console.log('callback eseguita')

      return
    }

    setTimeout(loop, 200)
  }

  loop()
}

function startDesktop() {
  const loadingScreen = document.querySelector('#loadingScreen')
  gsap.to(loadingScreen, {
    opacity: 0,
    duration: 0.5,
    scale: 2,
    ease: 'power3.in',
    onComplete: () => {
      // document.querySelector('.loadingScreen').remove()
      loadingScreen.style.display = 'none'
      // progressBar.style.width = `0%`
      // loadingText.innerHTML = `0 %`
    },
  })
}

export { animateLoadingScreen }
